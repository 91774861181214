import React, { useState, useRef, forwardRef } from 'react';
import { Button, Grid, useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import MainCard from 'ui-component/cards/MainCard';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import Loader from 'ui-component/Loader';

const BarcodeLabel = forwardRef(({ ...props }, ref) => {
    return (
        <div
            ref={ref}
            style={{
                width: '1.5in',
                height: '0.9in',
                fontFamily: 'Verdana',
                margin: '1.4in 0in 0in -0.3in',
                transform: 'rotate(90deg)'
            }}
        >
            <div
                style={{
                    width: '1.5in',
                    height: '1in',
                    fontFamily: 'Verdana',
                    overflow: 'hidden',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                }}
            >
                <div
                    style={{
                        marginTop: '0.2in',
                        fontSize: '6px',
                        lineHeight: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <span style={{ fontSize: '6px', fontFamily: 'Verdana', fontWeight: 'bold' }}> {props.productName}</span>
                            <br />
                            <span style={{ fontSize: '6px', fontFamily: 'Verdana', fontWeight: 'bold' }}> {props.code}</span>
                            <br />
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '6px' }}>
                                {props.price + ' '}
                                {props.currency_item ? props.currency_item : 'TND'}
                            </span>
                        </div>
                    </div>
                    {props.variants && props.variants.length > 0 && (
                        <span>
                            <span>{props.variants.map((variant) => variant.attribute + ' : ' + variant.value).join(' / ')}</span>
                        </span>
                    )}
                </div>
                <div style={{ textAlign: 'center', marginLeft: '0.1in' }}>
                    <Barcode value={props.barcodeValue} width={0.8} height={16} displayValue={true} fontSize={7} margin={0} />
                </div>
            </div>
        </div>
    );
});

const EtiquetteBarcode = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const printRef = useRef();
    const multiPrintRef = useRef();

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [directPrintProduct, setDirectPrintProduct] = useState(null);

    const itemsReqData = {
        doctype: 'Item',
        fields: [
            '`tabItem`.`name`',
            '`tabItem`.`item_name`',
            '`tabItem`.`item_code`',
            '`tabItem`.`description`',
            '`tabItem`.`item_group`',
            '`tabItem`.`prix_de_vente_ht`',
            '`tabItem Barcode`.`barcode`',
            '`tabItem`.`currency_item`',
            '`tabItem`.`has_variants`',
            '`tabItem Variant Attribute`.`attribute`',
            '`tabItem Variant Attribute`.`attribute_value`'
        ],
        //  filters: [["Item Barcode","barcode",">","0"]],

        filters: [
            ['Item Barcode', 'barcode', '>', '0'],
            ['has_variants', '=', 0]
        ]
    };

    const { data: productsData, isPending, refetch } = useFetch(`/api/get-list`, itemsReqData, 'POST');

    const consolidateProducts = (products) => {
        const consolidated = {};

        products.forEach((product) => {
            const { item_code, attribute, attribute_value } = product;

            if (!consolidated[item_code]) {
                consolidated[item_code] = {
                    ...product,
                    variants: []
                };
            }

            if (attribute && attribute_value) {
                consolidated[item_code].variants.push({
                    attribute,
                    value: attribute_value
                });
            }
        });

        return Object.values(consolidated);
    };

    const rows =
        productsData && productsData.length > 0
            ? consolidateProducts(productsData).map((product, index) => ({
                  id: product.item_code, // Assuming `item_code` is unique
                  name: product.name,

                  item_name: product.item_name,
                  description: product.description,
                  barcode: product.barcode,
                  prix_de_vente_ht: product.prix_de_vente_ht,
                  currency_item: product.currency_item,
                  variants: product.variants
              }))
            : [];

    const columns = [
        { field: 'name', headerName: t('common:itemCode'), flex: 1 },
        { field: 'item_name', headerName: t('products:productName'), flex: 1 },
        { field: 'description', headerName: t('common:description'), flex: 1 },
        { field: 'barcode', headerName: t('common:barcode'), flex: 1 },
        { field: 'prix_de_vente_ht', headerName: t('dashboard:standardRate'), flex: 1 },
        {
            field: 'actions',
            headerName: t('common:actions'),
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <Button variant="contained" color="primary" size="small" onClick={() => handleDirectPrint(params.row)}>
                        Print Barcode
                    </Button>
                </Box>
            )
        }
    ];

    const handleDirectPrint = (product) => {
        setDirectPrintProduct(product);
        setTimeout(() => {
            printRef.current.click();
        }, 0);
    };

    const handleRowSelection = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
        setSelectedProducts(selectedRows);
    };

    return (
        <MainCard title={t('products:productsList')}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}></Grid>
            <Box m={2}>
                {isPending ? (
                    <Loader />
                ) : (
                    <>
                        {rows.length > 0 ? (
                            <DataGrid
                                slotProps={{ toolbar: { showQuickFilter: true } }}
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                slots={{ toolbar: GridToolbar }}
                                autoHeight
                                disableRowSelectionOnClick
                                checkboxSelection
                                onRowSelectionModelChange={handleRowSelection}
                                sx={{ '& .MuiDataGrid-columnHeader': { fontWeight: 'bold' } }}
                            />
                        ) : (
                            <Typography variant="h6" align="center">
                                No Data Available
                            </Typography>
                        )}
                    </>
                )}

                {directPrintProduct && directPrintProduct && (
                    <div style={{ display: 'none' }}>
                        <BarcodeLabel
                            ref={multiPrintRef}
                            productName={directPrintProduct.item_name}
                            price={directPrintProduct.prix_de_vente_ht}
                            barcodeValue={directPrintProduct.barcode}
                            currency_item={directPrintProduct.currency_item}
                            variants={directPrintProduct.variants}
                            code={directPrintProduct.id}
                        />
                        <ReactToPrint
                            trigger={() => (
                                <button ref={printRef} style={{ display: 'none' }}>
                                    Print
                                </button>
                            )}
                            content={() => multiPrintRef.current}
                            pageStyle="@page { size: 40mm 25mm; margin:0 }"
                            onAfterPrint={() => setDirectPrintProduct(null)}
                        />
                    </div>
                )}

                <div style={{ display: 'none' }}>
                    {selectedProducts.map((product) => (
                        <BarcodeLabel
                            key={product.id}
                            ref={multiPrintRef}
                            productName={product.item_name}
                            price={product.prix_de_vente_ht}
                            barcodeValue={product.barcode}
                            currency_item={product.currency_item}
                            variants={product.variants}
                            code={product.id}
                        />
                    ))}
                </div>
            </Box>
        </MainCard>
    );
};

export default EtiquetteBarcode;
