import React, { useState, useRef } from 'react';
import { Button, Checkbox } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import readXlsxFile from 'read-excel-file';

const DataImporter = ({ handleClose, refetch, columns, doctype }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [checked, setChecked] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const { t } = useTranslation(['buying', 'common']);
    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLoadingButton(true);
        // Handle the selected file here, e.g., upload it to the server
        if (file) {
            //  uploadFile(file);
            readExcel(file);
        }
    };
    const readExcel = (file) => {
        readXlsxFile(file)
            .then((rows) => {
                // Traverse through each row
                for (let i = 1; i < rows.length; i++) {
                    const row = rows[i];
                    if (row.length > 0) {
                        verificationItem(row[0]);
                    }
                }
            })
            .catch((error) => console.error(error));
    };
    const verificationItem = (name) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user')).token
            },
            body: JSON.stringify({
                doctype: 'Item',

                filters: [['Item', 'name', '=', name]]
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data[0] && data[0].name !== undefined && data[0].name !== null) {
                    dispatch(
                        SetNotification({
                            code: 'error',
                            message: `Item ${name} already exists`
                        })
                    );
                    setLoadingButton(false);
                } else {
                    uploadFile(fileInputRef.current.files[0]);
                    setLoadingButton(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                // Handle error as needed
            });
    };
    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('docname', 'Item');

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URI}/api/upload-item-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            dispatch(
                SetNotification({
                    code: 'success',
                    message: t('common:Item_imported_successfully')
                })
            );
            handleClose(true);
            window.location.reload();
        } catch (error) {
            console.error('Error uploading file:', error.response || error);
            dispatch(SetNotification({ code: 'error', message: 'Something went wrong' }));
            handleClose(true);
            console.error('Error uploading file:', error);
        }
    };
    const handleDownloadClick = () => {
        // Simulating download from a source
        const fileUrl = process.env.PUBLIC_URL + '/assets/file/Template_Product.xlsx';

        window.open(fileUrl);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={handleDownloadClick}
                    startIcon={<CloudDownloadIcon />}
                    style={{ marginBottom: '10px' }}
                >
                    {t('common:DownloadTemplateForImportItem')}
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'center',
                    fontSize: '12px',
                    marginBottom: '10px',
                    marginTop: '5px',
                    color: 'red'
                }}
            >
                <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                {t('common:respectFileRegulations')}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    disabled={loadingButton}
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={handleIconClick}
                    startIcon={<CloudUploadIcon />}
                    style={{ marginBottom: '10px', visibility: checked ? 'visible' : 'hidden' }}
                >
                    {t('common:importFile')}
                </Button>
            </div>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".xlsx, .xls" onChange={handleFileChange} />
        </div>
    );
};

export default DataImporter;
