{
    /* eslint-disable */
}
import { Button, Grid, Modal, Typography, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DataRender from 'ui-component/tables/DataRender';
import useFetch from 'hooks/useFetch';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';
import { modalStyle } from 'views/utilities/ModalStyle';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: 'none'
};
const PriceList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [selectionModel, setSelectionModel] = useState([]);
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const pricelistReqData = {
        doctype: 'Price List',
        fields: [
            '`tabPrice List`.`name`',
            '`tabPrice List`.`price_code`',
            '`tabPrice List`.`price_list_name`',
            '`tabPrice List`.`enabled`',
            '`tabPrice List`.`currency`',
            '`tabPrice List`.`creation`'
        ],
        filters: [['Price List', 'buying', '=', 1]],
        order_by: '`tabPrice List`.`modified` desc',
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, pricelistReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/oms/edit-pricelist/${row}`);
    };

    const handleClick = () => {
        handleOpen();
    };
    function substringUntilWord(str, word) {
        // Retirer tous les espaces de la chaîne de caractères
        const noSpacesStr = str.replace(/\s+/g, '');

        // Trouver la position du mot recherché
        const index = noSpacesStr.indexOf(word);

        // Si le mot n'existe pas, retourner une chaîne vide
        if (index === -1) {
            return '';
        }

        // Retourner la sous-chaîne depuis le début jusqu'au mot inclus
        return noSpacesStr.substring(0, index + word.length);
    }

    function cutAndCleanWord(str, word) {
        // Remove all spaces from the word
        const cleanWord = word.replace(/\s+/g, '');

        // Find the index of the clean word in the input string
        const index = str.indexOf(cleanWord);

        // If the word is found, remove the portion up to and including the clean word
        if (index !== -1) {
            // Cut the string from the end of the clean word to the end of the input string
            return str.substring(index + cleanWord.length).trim();
        }

        // If the clean word is not found, return the original string
        return str;
    }
    // Rest of the code...

    useEffect(() => {
        console.log(data); // log the data to console
    }, [data]);

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Price List', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(
                        SetNotification({
                            code: 'warning',
                            message:
                                t(`core:${substringUntilWord(formatErpResponse(data?.message), 'Invoice')}`) +
                                ' ' +
                                cutAndCleanWord(formatErpResponse(data?.message), 'Invoice')
                        })
                    );
                } else {
                    dispatch(SetNotification({ message: t('common:typeTarifDeleteSuccess'), code: 'success' }));
                    handleClose();
                    refetch({});
                }
            });
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('core:pricelist')}
            <Box>
                <Grid container spacing={1}>
                    {false && (
                        <>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<DeleteIcon color="error" />}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleClick('remove')}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                {selectionModel.length === 1 && (
                                    <Button
                                        startIcon={<EditIcon />}
                                        variant="outlined"
                                        color="blue"
                                        onClick={() => handleEdit(selectionModel)}
                                        sx={{ marginTop: -3 }}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/oms/new-pricelist')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('core:addpricelist')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                tag={t('common:codeTarif')}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                notshow={['name']}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </div>
    );
};

export default PriceList;
