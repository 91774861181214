import { Box, Button, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import SelectForm from 'ui-component/form/SelectForm';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { t } from 'i18next';

function ItemPriceForm({ setItemPriceState, itemPriceState, doc, disabled, priceLists }) {
    const dispatch = useDispatch();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRow2Insert({
            doctype: 'Item Price',
            price_list: '',
            price_list_rate: 0,
            item_code: doc.name,
            currency: globalDefaults?.default_currency
        });
    };

    const [row2Insert, setRow2Insert] = useState({
        doctype: 'Item Price',
        price_list: '',
        price_list_rate: 0,
        item_code: doc.name,
        currency: globalDefaults?.default_currency
    });

    const handleChangeValues = (e) => {
        setRow2Insert({ ...row2Insert, [e.target.name]: e.target.value });
    };

    const handleInsert = () => {
        setItemPriceState([...itemPriceState, row2Insert]);
        handleClose();
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleSubmit = async () => {
        setLoadingButton(true);

        const filteredDocs = itemPriceState?.filter((doc) => doc?.doctype === 'Item Price');

        const requests = filteredDocs?.map((doc) =>
            fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ doc: doc, action: 'Save' })
            })
        );

        Promise.all(requests)
            .then((responses) => Promise.all(responses.map((res) => res.json())))
            .then((dataArr) => {
                setLoadingButton(false);
                dataArr.forEach((data) => {
                    if (data?.success === false) {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    } else {
                        dispatch(SetNotification({ code: 'success', message: t('products:price_saved_succesfully') }));
                    }
                });
            });
    };

    const handleDelete = (el) => {
        setItemPriceState(
            itemPriceState.filter((row) => !(row.price_list === el.price_list && row.price_list_rate === el.price_list_rate))
        );
    };

    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        {t('products:newitemP')}
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={disabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('core:add')}
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{t('columns:price_list_name')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{t('products:price_list_rate')}</strong>
                            </TableCell>
                            <TableCell align="right" width={50}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemPriceState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.price_list}</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={el?.currency}
                                            minimumFractionDigits={3}
                                            value={el?.price_list_rate}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell align="right" width={50}>
                                    <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || itemPriceState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.price_list}
                                        name="price_list"
                                        data={priceLists}
                                        propToRender={'name'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.price_list_rate}
                                        onChange={handleChangeValues}
                                        name="price_list_rate"
                                        placeholder={'Rate'}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton onClick={handleInsert} disabled={!row2Insert.price_list}>
                                            <DoneIcon color={!row2Insert.price_list ? 'disabled' : ''} />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {itemPriceState?.filter((doc) => doc?.doctype === 'Item Price')?.length > 0 ? (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <LoadingButton variant="contained" color="blue" loading={loadingButton} onClick={handleSubmit} disabled={disabled}>
                            Save
                        </LoadingButton>
                    </Box>
                ) : null}
            </Box>
        </div>
    );
}

export default ItemPriceForm;
