import { Box, Button, Grid } from '@mui/material';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Loader from 'ui-component/Loader';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';

function FOMFormEdit() {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=UOM Category&name=${id}`);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [loadingButton, setLoadingButton] = useState(false);

    const [state, setState] = useState({
        enabled: 1,
        must_be_whole_number: 0,
        fom_name: ''
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSaveFOM = async () => {
        setLoadingButton(true);
        const data = {
            family_category_new_name: state.fom_name,
            family_category_name: id
        };
        console.log(data);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-measures-family`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    console.log(data);
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:fom-create') }));
                    navigate('/pim/family-measures');
                }
            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                ...state,
                enabled: doc?.enabled,
                must_be_whole_number: doc?.must_be_whole_number,
                fom_name: doc?.fom_name || doc?.category_name || ''
            });
        }
    }, [id, doc]);

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                <Box sx={id && doc?.enabled === 0 ? { opacity: 0.7 } : {}}>
                    {id ? <span>{t('common:famille-de-mesure') + ' : ' + id}</span> : <span>{t('common:uom')}</span>}
                </Box>
            </Box>
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <MainCard title={title}>
            <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                <InputTextField
                    required
                    error={state.fom_name === ''}
                    label={t('common:nom-famille')}
                    value={state.fom_name}
                    name="fom_name"
                    handleChange={handleChange}
                />
            </Grid>
            <Grid container xs={12} sm={12} md={8} lg={12} xl={12}>
                <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'} mt={4}>
                        <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                            {t('common:cancel')}
                        </Button>
                        <LoadingButton onClick={handleSaveFOM} variant="contained" color="blue" size="large" loading={loadingButton}>
                            {t('common:save')}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default FOMFormEdit;
