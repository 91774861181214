import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import { useSelector } from 'react-redux';
import { Avatar, Button, Card, CardContent, Chip, Divider, Typography, Modal } from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import { modalStyle } from 'views/utilities/ModalStyle';
import { useTranslation } from 'react-i18next';
import ContactsIcon from '@mui/icons-material/Contacts';
import SuppliersFormEdit from '../SupplierFormEdit';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    username: {
        fontWeight: 'bold'
    },
    divider: {
        width: '100%',
        margin: theme.spacing(2, 0)
    },
    title: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2)
    },
    text: {
        marginTop: theme.spacing(1)
    },
    pointsCard: {
        padding: 10,
        marginTop: 16,
        borderRadius: 10,
        backgroundColor: theme.palette.grey[200]
    }
}));

const SupplierDetailsCard = ({ doc, refetch }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [openEditSupplier, setOpenEditSupplier] = useState(false);
    const [loadingButtonForm, setLoadingButtonForm] = useState(false);

    const handleCloseForm = () => setOpenEditSupplier(false);
    const editSupplier = (
        <Box
            sx={{
                modalStyle,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%', // Adjust the width as needed
                maxHeight: '100vh', // Set a maximum height to make the form scrollable
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <SuppliersFormEdit
                handleClose={handleCloseForm}
                loadingButton={loadingButtonForm}
                setLoadingButton={setLoadingButtonForm}
                refetch={refetch}
                doc={doc}
                isEdit={true}
            />
        </Box>
    );

    const primary_address = doc?.__onload?.addr_list && doc?.__onload?.addr_list.filter((p) => p.is_primary_address == 1);
    const addr_list = doc?.__onload?.addr_list && doc?.__onload?.addr_list.filter((p) => p.is_primary_address == 0);

    return (
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Box display="flex" justifyContent={'space-between'} height={33}>
                        <Box display={'flex'} flexDirection={'row'} alignItems="center">
                            <Avatar className={classes.avatar} />
                            <Typography className={classes.username}>{doc?.supplier_name}</Typography>
                        </Box>
                        <Button
                            startIcon={<EditIcon />}
                            variant="outlined"
                            color="blue"
                            sx={{ ml: 1 }}
                            onClick={() => {
                                setOpenEditSupplier(true);
                            }}
                        >
                            {t('crm:edit')}
                        </Button>
                    </Box>
                    <Divider className={classes.divider} />
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('columns:supplierName')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        {doc?.supplier_name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('columns:supplierCode')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        {doc?.fournisseur_code}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('columns:supplierGroup')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        {doc?.supplier_group}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('columns:supplierType')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        {doc?.supplier_type}
                    </Typography>
                    {doc?.mobile_no && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                {t('crm:phonenum')}:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {doc?.mobile_no}
                            </Typography>
                        </>
                    )}
                    {doc?.email_id && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                Email:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {doc?.email_id}
                            </Typography>
                        </>
                    )}

                    {primary_address?.length != 0 && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                {t('crm:addr')}:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {formatErpResponse(primary_address[0]?.display)}
                            </Typography>
                        </>
                    )}
                    {doc?.country && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                {t('core:country')}:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {doc?.country}
                            </Typography>
                        </>
                    )}
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('crm:annbill')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        <CurrencyFormatter
                            locale="fr-FR"
                            currency={globalDefaults?.default_currency}
                            minimumFractionDigits={3}
                            value={doc?.__onload?.dashboard_info[0]?.billing_this_year ?? 0}
                        />
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('crm:ttlunpaid')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        <CurrencyFormatter
                            locale="fr-FR"
                            currency={globalDefaults?.default_currency}
                            minimumFractionDigits={3}
                            value={doc?.__onload?.dashboard_info[0]?.total_unpaid ?? 0}
                        />
                    </Typography>
                    <Divider sx={{ m: 2 }}>
                        <Chip
                            label={
                                <Box display={'flex'} alignItems={'center'} fontWeight={'bold'} color={'CaptionText'}>
                                    {t('core:otherAddress')}: <ContactsIcon sx={{ width: 20, ml: 1 }} />
                                </Box>
                            }
                        />
                    </Divider>

                    {addr_list &&
                        addr_list.map((p, index) => (
                            <>
                                <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                    {t('crm:addr')} {index + 1}:
                                </Typography>
                                <Typography variant="body1" className={classes.text}>
                                    {formatErpResponse(p?.display)}
                                </Typography>
                            </>
                        ))}
                </CardContent>
            </Card>
            <Modal open={openEditSupplier} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                {editSupplier}
            </Modal>
        </div>
    );
};

export default SupplierDetailsCard;
