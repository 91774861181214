{/* eslint-disable */}
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, FormControlLabel, Checkbox } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import SelectForm from 'ui-component/form/SelectForm';
import useFetch from 'hooks/useFetch';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const DiscountForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { id } = useParams();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Discount&name=${id}`);

    const [loadingButton, setLoadingButton] = useState(false);

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`'],
        start: 0,
        page_length: 500
    };

    const { data: warehouses, isPending: isPendingWarehouse } = useFetch(`/api/get-list`, warehouseReqData, 'POST');

    const [isEnabled, setIsEnabled] = useState(false);
    const [initialEnabledState, setInitialEnabledState] = useState(true);
    const [state, setState] = useState({
        discount_value: '',
        warehouse: '',
        discount_code: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'discount_value') {
            // Parse the input value as a number
            const parsedValue = parseFloat(value);

            // Check if the parsed value is a valid number and within the desired range
            if (isNaN(parsedValue) || parsedValue <= 0 || parsedValue > 100) {
                return; // Skip updating the state if the value is invalid
            }
        }

        setState({ ...state, [name]: value });
    };

    const editDiscount = {
        ...(id && {
            creation: doc.creation,
            modified: doc.modified,
            owner: doc.owner,
            modified_by: doc.owner,
            name: doc.name
        }),
        doctype: 'Discount',
        discount_value: state.discount_value,
        warehouse: state.warehouse,
        enabled: isEnabled ? 1 : 0,
        discount_code: state.discount_code
    };

    const handleEditDiscount = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: editDiscount, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    let successMessage;
                    if (id) {
                        if (isEnabled !== initialEnabledState) {
                            successMessage = t('common:statRemiseModifiedSuccess');
                        } else {
                            successMessage = t('common:remiseModifiedSuccess');
                        }
                    } else {
                        successMessage = t('common:remiseCreateSuccess');
                    }
                    dispatch(SetNotification({ code: 'success', message: successMessage }));
                    navigate('/core/discount');
                }

            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                ...state,
                discount_value: doc.discount_value,
                warehouse: doc.warehouse,
                discount_code: doc.discount_code
            });
            setIsEnabled(doc.enabled == 1 ? true : false);
            setInitialEnabledState(doc.enabled == 1 ? true : false);
        }
    }, [id, doc]);

    const getTitle = () => {
        if (id && state.discount_code) {
            return (
                <Box display={'flex'} flexDirection={'column'}>
                    <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                    {t('core:edit')}: {state.discount_code}
                </Box>
            );
        } else {
            return (
                <Box display={'flex'} flexDirection={'column'}>
                    <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                    {t('core:newdiscnt')}
                </Box>
            );
        }
    };

    if (id && isPending && isPendingWarehouse) return <Loader />;

    return (
        <Box>
            <MainCard title={getTitle()}>
                <Box>
                    <Box>
                        <FormControlLabel
                            label={t('core:enabled')}
                            control={<Checkbox color="blue" checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />}
                        />

                        <InputTextField
                            sx={{ mt: 0 }}
                            error={state.discount_value === ''}
                            handleChange={handleChange}
                            value={state.discount_value}
                            name="discount_value"
                            required
                            label={`${t('core:discnt')} (%)`}
                            id="outlined-size-small"
                            size="small"
                            type="number"
                        />
                        <InputTextField
                            sx={{ mt: 0 }}
                            error={state.discount_code === ''}
                            handleChange={handleChange}
                            value={state.discount_code}
                            name="discount_code"
                            required
                            label={t('core:discntcode')}
                            id="outlined-size-small"
                            size="small"
                            type="text"
                        />
                        <SelectForm
                            sx={{ mt: -2 }}
                            error={state.warehouse == ''}
                            label={t('core:store')}
                            propToRender={'name'}
                            name="warehouse"
                            value={state.warehouse}
                            data={warehouses ?? warehouses}
                            handleChange={handleChange}
                        />
                    </Box>
                </Box>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                disabled={!state.discount_value || state.discount_value >= 100 || !state.warehouse || !state.discount_code}
                                size="large"
                                variant="contained"
                                color="blue"
                                loading={loadingButton}
                                onClick={handleEditDiscount}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default DiscountForm;
